<template>
    <div>
        <div id="mychartWorkorder" class="mychartWorkOrder"></div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            echarts: null,
            errorTypeDic: []
        }
    },
    props: {
        reqData: {
            default: ''
        }
    },
    watch: {
        reqData: {
            deep: true,
            immediate: true,
            handler (val) {
                this.getWorkOrderTypeStat()
            }
        }
    },
    mounted () {
        this.getErrorTypeDic()
    },
    methods: {
        getWorkOrderTypeStat () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderTypeStat,
                method: "post",
                showLoading: false,
                params: {
                    ...this.reqData
                }
            }).then(({data}) => {
                let dataArr = data.map(item=>{
                    return {
                        name: this.getErrorName(item.type),
                        value: item.stat
                    }
                })
                this.drawChart(dataArr)
            })
        },
        getErrorName (code) {
            return this.errorTypeDic.find(res=>{
                return res.value == code
            }).label
        },
        getErrorTypeDic () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorType,
                method: "post",
                params: {},
            })
            .then(({ data })=>{
                this.errorTypeDic = data.map(item=>{
                    return {
                        label: item.name,
                        value: item.id
                    }
                })
            })
            .catch(err=>{

            })
        },
        drawChart (data=[]) {
            var chartDom = document.getElementById('mychartWorkorder');
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#29CC29', '#007AFF', '#FF3B30', '#FFA900'],
                legend: {
                    top: '20%',
                    right: '60%',
                    orient: 'vertical',
                    width: '160',
                    padding: [0, 0, 0, 0],
                    itemGap: 18,
                    itemWidth: 8,
                    itemHeight: 8,
                    icon: 'circle',
                    formatter: function (params) {
                        let a;
                        let b;
                        data.forEach((item, index) => {
                            if (item.name == params) {
                                if (item.name == '使用中') {
                                    a = "${item.name}    ${item.value}"
                                } else {
                                    a = "${item.name}    ${item.value}"
                                }

                                b = eval('`' + a + '`')
                            }
                        })

                        return b
                    }
                },
                series: [
                    {
                        name: '工单类型分布',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 6,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            normal: {
                                show: false,
                            }
                        },
                        center: ['60%', '50%'],
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '12',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: data
                    }
                ]
            }
            if (!!chartDom) {
                this.echarts = this.$echarts.init(chartDom);
                this.echarts.setOption(option, true);
            }

            window.addEventListener("resize", () => {
                if (this.echarts) {
                    this.echarts.resize()
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .mychartWorkOrder{
        width: 100%;
        height: 160px;
        margin-top: 20px;
    }
</style>