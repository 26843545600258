<template>
    <div class="app-body content a-flex-cfsfs">
        <div class="a-flex-rfsfs a-mt-16 a-flex-1" style="align-items: stretch;">
            <div class="a-flex-cfsfs" style="flex:2;align-items: stretch;">
                <div class="content-layout a-flex-1" >
                    <span class="content-main-title">工单情况/工单类型分布</span>
                    <div class="a-flex-rfsfs a-mt-20" style="align-items: stretch;">
                        <div class="a-flex-cfsfs content-main-card a-flex-1">
                            <div class="a-flex-rfsbl">
                                <span>工单处理平均时效为</span>
                                <span class="content-main-value">{{ workOrderInfo.avgDownDay || 0 }}</span>
                                <span>天</span>
                            </div>
                            <div class="a-flex-rfsbl a-mt-10">
                                <span>最近30天平均时效为</span>
                                <span class="content-main-value">{{ workOrderInfo.lastThirtyDayDownDay || 0 }}</span>
                                <span>天</span>
                            </div>
                        </div>
                        <div class="a-flex-cfsfs content-main-card a-ml-20" style="flex: 0.8">
                            <div class="a-flex-rfsbl">
                                <span>当前进行中的工单</span>
                                <span class="content-main-value">{{ workOrderInfo.processingTotalNum || 0 }}</span>
                                <span>个</span>
                            </div>
                        </div>
                    </div>
                    <workorder-charts-type :reqData="form"></workorder-charts-type>
                </div>
                <div class="content-layout a-flex-cfsfs a-mt-16 a-flex-1" style="align-items: stretch">
                    <span class="content-main-title">高频异常问题</span>
                    <div class="a-flex-cfsfs a-mt-20 a-flex-1 workOrderErrorStat-content" style="align-items: stretch;overflow-y: scroll;">
                        <div class="a-flex-rfsfs a-fs-14 workOrderErrorStat-content-item" v-for="(item,index) in workOrderErrorStat" :key="index">
                            <span>{{ index + 1 }}. </span>
                            <span>{{ item.question || '' }}</span>
                            <span class="a-plr-10">|</span>
                            <span class="a-flex-1 a-c-blue">{{ item.questionItem || '' }}</span>
                            <span>累计</span>
                            <span class="content-main-value">{{ item.stat }}</span>
                            <span>次</span>
                        </div>
                    </div>
                    <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!workOrderErrorStat.length">
                        <el-empty :image-size='100' description="暂无记录"></el-empty>
                    </div>
                </div>
            </div>
            <div class="content-layout a-ml-16 a-flex-cfsfs" style="flex: 1;align-items:stretch">
                <span class="content-main-title">进行中的工单</span>
                <el-select class="a-mt-20" style="max-width: 200px" v-model="workorderStatus" @change='workorderStatusChange' placeholder="请选择状态">
                    <el-option
                        v-for="item in workorderStatusDic"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="a-flex-cfsfs a-mt-20 a-flex-1 workOrderErrorStat-content" style="align-items: stretch;">
                    <el-collapse v-model="activeNames" @change="handleChange" accordion>
                        <el-collapse-item :name="item.rbacUserId" v-for="(item,index) in workOrderRbacUserInfo" :key="index">
                            <div slot="title" class="a-flex-rfsfs a-w-100 a-fs-14 workOrderErrorStat-content-item font-point" >
                                <!-- <span>{{ index + 1 }}. </span> -->
                                <span>{{ item.rbacUserName || '' }}</span>
                                <span>-</span>
                                <span>{{ item.mobile || '' }}</span>
                                <span>-</span>
                                <span class="a-flex-1 textOver1">{{ item.companyName || '' }}</span>
                                <span class="content-main-value">{{ item.num }}</span>
                                <span>个{{ !workorderStatus ? '' : workorderStatus == 1 || workorderStatus == 4 ? '待处理' : '进行中' }}</span>
                            </div>
                            <div class="a-flex-cfsfs a-fs-14 a-c-666 a-plr-20 rbacUserInfoDetails" style="align-items: stretch;">
                                <span class="a-fs-14 a-fw-700 a-c-333">{{ item.rbacUserName }}进行中的工单</span>
                                <div class="a-flex-rfsc a-mt-10">
                                    <span class="a-flex-1">待处理：{{ item.rbacUserInfoDetails.pendingNum || 0 }}</span>
                                    <span class="a-flex-1">处理中：{{ item.rbacUserInfoDetails.processingNum || 0 }}</span>
                                </div>
                                <div class="a-flex-rfsc a-mt-10">
                                    <span class="a-flex-1">超时待处理：{{ item.rbacUserInfoDetails.overdueNum || 0 }}</span>
                                    <span class="a-flex-1">超时处理中：{{ item.rbacUserInfoDetails.processingOverdueNum || 0 }}</span>
                                </div>
                                <span class="a-c-333 a-fw-700 a-mt-10">{{ item.rbacUserName }}已处理工单：{{ item.rbacUserInfoDetails.downNum || 0 }}</span>
                                <span class="a-c-333 a-fw-700 a-mt-10">处理工单平均耗时：{{ item.rbacUserInfoDetails.avgHour | initTime }}</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div style="width: 100%;height:100%" class="a-flex-rcc" v-if="!workOrderRbacUserInfo.length">
                    <el-empty :image-size='100' description="暂无记录"></el-empty>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import workorderChartsType from './inspection/workorder-charts-type.vue'
export default {
  components: { workorderChartsType },
    data () {
        return {
            form: {
                companyId: '',
                provinceCode: '',
                cityCode: '',
                districtCode: ''
            },
            workOrderInfo: {},
            workOrderErrorStat: [],
            workOrderRbacUserInfo: [],
            workorderStatus: '',
            workorderStatusDic: [{
                label: '全部',
                value: ''
            },{
                label: '待处理',
                value: '1'
            },{
                label: '处理中',
                value: '2'
            },{
                label: '超时待处理',
                value: '4'
            },{
                label: '超时处理中',
                value: '6'
            }],
            activeNames: ''
        }
    },
    mounted () {
        this.form.companyId = this.$route.query.companyId
        this.form.provinceCode = this.$route.query.provinceCode
        this.form.cityCode = this.$route.query.cityCode
        this.form.districtCode = this.$route.query.districtCode
        this.getWorkOrderNowInfo()
        this.getWorkOrderErrorStat()
        this.getWorkOrderRbacUserInfo()
    },
    filters: {
        initTime (time) {
            if(!time) {
                return '-'
            }
            if(time > 24) {
                let day = parseInt(time / 24) 
                let hour = time % 24
                return day + '天' + hour + '小时'
            }else {
                return time + '小时'
            }
        }
    },
    methods: {
        getWorkOrderNowInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderNowInfo,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form
                }
            }).then(({data}) => {
                this.workOrderInfo = Object.assign({}, data)
            })
        },
        getWorkOrderErrorStat () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderErrorStat,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form
                }
            }).then(({data}) => {
                this.workOrderErrorStat = data.filter(res=>{
                    return res.stat >= 5
                })
                // for (let index = 0; index < 10; index++) {
                //     this.workOrderErrorStat = this.workOrderErrorStat.concat(data)

                    
                // }
            })
        },
        getWorkOrderRbacUserInfo () {
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderRbacUserInfo,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form,
                    status: this.workorderStatus
                }
            }).then(({data}) => {
                this.workOrderRbacUserInfo = data.map(item=>{
                    return {
                        ...item,
                        rbacUserInfoDetails: ''
                    }
                })
                // for (let index = 0; index < 10; index++) {
                //     this.workOrderErrorStat = this.workOrderErrorStat.concat(data)

                    
                // }
            })
        },
        workorderStatusChange () {
            this.getWorkOrderRbacUserInfo()
        },
        handleChange () {
            if(!this.activeNames) {
                return
            }
            this.$Axios._post({
                url: this.$Config.apiUrl.getWorkOrderRbacUserInfoDetail,
                method: "post",
                showLoading: false,
                params: {
                    ...this.form,
                    rbacUserId: this.activeNames
                }
            }).then(({data}) => {
                this.workOrderRbacUserInfo.map(item=>{
                    if(item.rbacUserId == this.activeNames) {
                        item.rbacUserInfoDetails = {
                            ...data
                        }
                    }
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content{
    margin-top: 16px;
    overflow-y: auto;
    padding-bottom: 32px;
    height: 100%;
    align-items: stretch;
    .content-layout{
        background: #ffffff;
        border-radius: 4px;
        padding: 20px;
        .content-main-title{
            font-size: 14px;
            color: #333333;
            font-weight: 700;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
        }
        .content-main-title::before{
            content: "";
            display: inline-block;
            width: 4px;
            height: 14px;
            border-radius: 4px;
            background: #007af0;
            margin-right: 8px;
        }
        .content-main-morebtn{
            font-size: 14px;
            color: #999999;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .content-main-card {
            padding: 12px;
            border-radius: 8px;
            font-size: 12px;
            color: #333333;
            background: rgb(242, 246, 252);
            font-weight: 500;
        }
        .content-main-value{
            font-size: 18px;
            font-weight: 700;
            padding: 0 10px;
            color: #FE7B11;
        }
        .workOrderErrorStat-content{

        }
        .workOrderErrorStat-content-item{
            padding: 5px 0;
        }
        .workOrderErrorStat-content-item:hover{
            background: #f2f2f2;
        }
        /deep/ .workOrderErrorStat-content::-webkit-scrollbar {
            /*width: 0;宽度为0隐藏*/
            width: 0 !important;
            height: 0 !important;
        }
        .rbacUserInfoDetails{
            background: #F2F6FC99;
            border-radius: 4px;
            padding: 16px;
        }
    }
}
    
</style>